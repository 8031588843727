:root {
    --main-color: #101831;
    --secundary-color: #1d212b;
    --main-font-size: 17px;
    --margin-body: 0px;
    --padding-body: 0px;
    --main-font-color: #ffffff;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

body {
    margin: var(--margin-body);
    padding: var(--padding-body);
    background-color: var(--main-color);
    color: var(--main-font-color);
    font-size: var(--main-font-size);


    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.links {
    text-decoration: none;
}