.footer-content {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
}

.footer-media-content {
    padding-left: 35px;
}

.footer-links {
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
}

.footer-links:hover {
    text-decoration: underline;
}


.footer-container {
    border-top: 1px solid #2c2f36;
    background-color: #040814;
    padding: 30px 50px;
    padding-bottom: 70px;
}

a.footer-content-media {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    font-size: 14px;
    text-decoration: none;
    color: white;
}

.links.footer-content-media:hover {
    text-decoration: underline;
}