.home-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.home-content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 700px;
}

section.section-home {
    height: 100vh;
}

.home-container {
    position: relative;
    top: 15%;
    z-index: 100;
}