img.tnks-img {
    width: 100%;
    height: 100%;
}

.img-container-tkns {
    width: 500px;
    height: auto;
    margin: 20px;
}

section.tokenomics-section {
    padding-bottom: 80px;
}

.tokenomics-container {
    padding: 20px 50px;
}

.tokenomics-content-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.tkns-content-text {
    width: calc(100% - 700px);
    padding: 20px;
    border: solid 1px #2c2f36;
    border-radius: 8px;
    background: hsl(233.13deg 84.72% 2.64% / 38%);

}

