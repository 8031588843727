.builder-tag-container {
    background-color: #040814;
    border-top: 1px solid #2c2f36;
    padding: 20px;
    color: #5b5b5b;
    font-size: 10px;
}

.builder-tag-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.builder-tag {
    display: flex;
    align-items: center;
    justify-content: center;
}

.builder-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
}

img.builder-tag {
    width: 100%;
    height: 100%;
}

.bulder-tag-copyright {
    padding: 0px 5px;
}

.bulder-tag {
    padding: 0px 10px;
}

.builder-tag-container {
    background-color: #040814;
    border-top: 1px solid #2c2f36;
    padding: 20px;
}