@media (max-width: 767px) {
    .home-content-box {
        width: 300px;
    }

    .features-image.fadeIn {
        display: none;
    }

    .features-content {
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
    }

    .content-item-keys {
        width: 100%;
        padding: 3px;
    }

    section#features {
        height: auto;
        padding-bottom: 30px;
    }

    .faqs-content {
        width: 300px;
    }

    div#accordion {
        font-size: 15px;
    }

    a.footer-content-media {
        font-size: 12px;
    }

    .footer-links {
        font-size: 12px;
    }

    .navbar-box-rs {
        display: block;
    }

    div.container-nav.inactive {
        position: fixed;
        display: flex;
        height: 100vh;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        background-color: #000000b3;
        backdrop-filter: blur(1px);
        z-index: 999;
        top: -1500%;
        transition: top 0.5s ease-in-out;
    }

    .title-navbar {
        font-size: 14px;
    }

    div.container-nav.active {
        display: flex;
        position: fixed;
        height: 100vh;
        left: 0;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        background-color: #000000b3;
        backdrop-filter: blur(1px);
        z-index: 999;
        top: 0;
        transition: top 0.5s ease-in-out;
    }

    ul.navbar-ul.ul-active {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    ul.navbar-ul.ul-inactive {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .menu-rs-box.close {
        position: fixed;
        z-index: 999;
        background-color: black;
        border-radius: 50%;
    }

    .menu-rs-box.close.btn-inactive {
        display: none;
    }

    .menu-rs-box.close.btn-active {
        display: block;
    }

    .img-container-tkns {
        display: none;
    }

    div.tkns-content-text {
        width: 100%;
    }
}