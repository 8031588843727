.faqs-content {
    width: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.faqs-content-box {
    width: 100%;
}

.faqs-content-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

section.faqs-section {
    height: auto;
    padding-bottom: 60px;
}