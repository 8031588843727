.features-image {
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: flex;
    width: 400px;
    height: 400px;
    mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 65%);
    -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 65%);
}

.img-features {
    width: 100%;
    height: 100%;
}

.features-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

section.features-section {
    height: 120vh;
}

.features-container {
    position: relative;
    padding: 20px 50px;
    box-sizing: border-box;
}

.left-side {
    text-decoration: underline;
    padding: 10px;
}

.content-item-keys {
    padding: 10px;
    margin: 5px;
    width: 70%;
    transition: transform 0.5s ease-in;
    border: 1px solid #2c2f36;
    border-radius: 15px;
    box-sizing: border-box;
    background: hsl(233.13deg 84.72% 2.64% / 38%);
}

.content-item-keys:hover {
    transform: scale(1.05);
}

.center-side {
    text-align: center;
}