.brand-logo {
    width: 100%;
    height: 100%;
}

.brand-logo-box {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80%;
}

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 50px;
}

.navbar-logo {
    display: flex;
    align-items: center;
    width: calc(100% / 2);
}

.navbar-box {
    width: calc(100% / 2);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

li.navbar-li {
    list-style: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background 1.5s ease;
    border-radius: 8px;
}

li.navbar-li:hover {
    background: hsla(0, 0%, 48%, 0.38);
    border-radius: 8px;
}

nav.navbar {
    position: fixed;
    width: 100%;
    background: hsl(233.13deg 84.72% 2.64% / 38%);
    backdrop-filter: blur(5px);
    z-index: 999;
}

.support-link {
    color: white;
}

img.navbar-rs-icon {
    width: 100%;
    height: 100%;
}

.navbar-box-rs-icon {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-box-rs {
    display: none;
}

.navbar-box.active {
    display: block;
}

.menu-rs-box {
    display: none;
}