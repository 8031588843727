.button-box {
    box-sizing: content-box;
    overflow: hidden;
    border-radius: 8px;
    width: 120px;
    text-align: center;
    transition: transform 1s ease-in-out;
}

.button-box:hover {
    transform: scale(1.1);
}

a.button-link {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container {
    padding: 20px;
}

.icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding: 5px;
}


img.icon-to {
    width: 100%;
    height: 100%;
}