.title-box {
    width: 100%;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    padding: 10px;
}